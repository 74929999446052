import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { NOTIFICATION_TYPES } from "../constants/notificationTypes";
import { useTranslation } from "react-i18next";
import notificationService from "../../../../services/notification.service";
import { toast } from "react-hot-toast";
import { BellIcon } from "@heroicons/react/24/outline";
import "./NotificationContext.css";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation(["notifications", "expense"]);
  const [lastToastTime, setLastToastTime] = useState(0);

  // 檢查未讀通知並顯示 toast
  const checkUnreadNotifications = useCallback(async () => {
    try {
      const response = await notificationService.getUnreadNotifications();
      const unreadCount = response.data?.length || 0;

      const now = Date.now();
      if (unreadCount > 0 && now - lastToastTime > 60000) {
        // 至少間隔 1 分鐘
        toast(
          (toastData) => (
            <div className="flex items-center">
              <BellIcon
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  marginRight: 4,
                  color: "#007bff",
                }}
                className="bell-animation"
              />
              <span className="text-sm ml-0.5">
                {t("notifications:unreadNotificationsAlert", {
                  count: unreadCount,
                })}
              </span>
            </div>
          ),
          {
            position: "top-right",
            style: {
              marginTop: "4rem",
              fontSize: "1rem",
              padding: "6px 8px",
              paddingLeft: 0,
            },
          }
        );
        setLastToastTime(now);
      }
    } catch (error) {
      console.error("Failed to check unread notifications:", error);
    }
  }, [t, lastToastTime]);

  // 在組件掛載時檢查未讀通知
  useEffect(() => {
    checkUnreadNotifications();
  }, [checkUnreadNotifications]);

  // 獲取未讀通知
  const fetchUnreadNotifications = useCallback(async () => {
    try {
      const response = await notificationService.getUnreadNotifications();
      setNotifications((prev) => [
        ...prev.filter((n) => n.type === NOTIFICATION_TYPES.PROFILE_INCOMPLETE),
        ...(response.data || []),
      ]);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  }, []);

  // 定期獲取通知
  useEffect(() => {
    fetchUnreadNotifications();

    // 當頁面處於活動狀態時使用較短的間隔
    const activeInterval = setInterval(fetchUnreadNotifications, 30000);

    // 當頁面不可見時清除定時器
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(activeInterval);
      } else {
        fetchUnreadNotifications();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(activeInterval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [fetchUnreadNotifications]);

  const markAsRead = useCallback(
    async (id) => {
      try {
        if (id === "profile-incomplete") {
          setNotifications((prev) =>
            prev.map((note) =>
              note.id === id ? { ...note, read: true } : note
            )
          );
        } else {
          await notificationService.markAsRead(id);
          await fetchUnreadNotifications();
        }
      } catch (error) {
        console.error("Failed to mark notification as read:", error);
      }
    },
    [fetchUnreadNotifications]
  );

  const checkUserProfileStatus = useCallback(
    (user) => {
      // 首先檢查 isProfileComplete 狀態
      if (!user?.user?.isProfileComplete) {
        setNotifications((prev) => {
          const filtered = prev.filter(
            (n) => n.type !== NOTIFICATION_TYPES.PROFILE_INCOMPLETE
          );
          return [
            {
              id: "profile-incomplete",
              type: NOTIFICATION_TYPES.PROFILE_INCOMPLETE,
              title: t("profile.completeProfile"),
              message: t("profile.requireOneFullName"),
              severity: "warning",
              read: false,
              createdAt: new Date(),
            },
            ...filtered,
          ];
        });
      } else {
        setNotifications((prev) =>
          prev.filter(
            (note) => note.type !== NOTIFICATION_TYPES.PROFILE_INCOMPLETE
          )
        );
      }
    },
    [t]
  );

  const contextValue = useMemo(
    () => ({
      notifications,
      markAsRead,
      checkUserProfileStatus,
      unreadCount: notifications.filter((n) => !n.read).length,
    }),
    [notifications, markAsRead, checkUserProfileStatus]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
