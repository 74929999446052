// store/descriptionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  description: "",
};

const descriptionSlice = createSlice({
  name: "description",
  initialState,
  reducers: {
    showDescriptionModal: (state, action) => {
      state.show = true;
      state.description = action.payload.description;
    },
    hideDescriptionModal: (state) => {
      state.show = false;
      state.description = "";
    },
  },
});

export const { showDescriptionModal, hideDescriptionModal } =
  descriptionSlice.actions;

export default descriptionSlice.reducer;
