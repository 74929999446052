import axiosInstance from "./axios.config.ts";

const API_URL = "/notifications";

class NotificationService {
  isLoginPage() {
    return window.location.pathname.includes("/login");
  }

  isAuthenticated() {
    const userData = localStorage.getItem("user");
    return !!userData;
  }

  getUnreadNotifications() {
    if (this.isLoginPage() || !this.isAuthenticated()) {
      return Promise.resolve({ data: [] });
    }
    return axiosInstance.get(`${API_URL}/unread`);
  }

  markAsRead(notificationId) {
    if (this.isLoginPage() || !this.isAuthenticated()) {
      return Promise.resolve();
    }
    return axiosInstance.put(`${API_URL}/${notificationId}/read`);
  }
}

export default new NotificationService();
