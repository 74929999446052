import { useEffect } from "react";

export const usePerformanceMonitor = (componentName) => {
  useEffect(() => {
    const startTime = performance.now();

    return () => {
      const endTime = performance.now();
      const duration = endTime - startTime;

      if (duration > 100) {
        // 設定閾值
        console.warn(`${componentName} 渲染時間過長: ${duration}ms`);
      }
    };
  });
};
