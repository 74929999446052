export const NOTIFICATION_TYPES = {
  PROFILE_INCOMPLETE: "profileIncomplete",
  SYSTEM_UPDATE: "systemUpdate",
  TASK_ASSIGNED: "taskAssigned",
  EXPENSE_REVIEWING: "expenseReviewing",
  EXPENSE_REVIEWED: "expenseReviewed",
  EXPENSE_APPROVING: "expenseApproving",
  EXPENSE_APPROVED: "expenseApproved",
  EXPENSE_PAID: "expensePaid",
  EXPENSE_REJECTED: "expenseRejected",
};

export const NOTIFICATION_SEVERITY = {
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success",
};
