import log from "loglevel";
import axios from "axios";

// 设置日志等级，生产环境为 "warn"，开发环境为 "debug"
log.setLevel(process.env.NODE_ENV === "production" ? "warn" : "debug");

// 创建发送日志到后端的函数
const sendLogToServer = (level, message) => {
  axios
    .post("/api/logs", {
      level,
      message: message.join(" "),
      timestamp: new Date().toISOString(),
    })
    .catch((err) => {
      console.error("Failed to send log to server:", err);
    });
};

// 自定义 `loglevel` 的方法，将日志同时记录在控制台并发送到后端
log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = log[methodName];
  return function (...args) {
    if (methodName === "warn" || methodName === "error") {
      rawMethod(...args); // 仅在开发环境或 WARN/ERROR 级别时输出到控制台
    }
    sendLogToServer(methodName, args); // 任何环境都发送到后端
  };
};

// 重新应用自定义的 `methodFactory`
log.setLevel(log.getLevel());

export default log;
