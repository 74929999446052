import axiosInstance from "./axios.config.ts";

const API_URL = "/currencies";

const getCurrencies = () => {
  return new Promise((resolve, reject) => {
    const checkToken = () => {
      if (localStorage.getItem("user")) {
        resolve(axiosInstance.get(API_URL));
      } else {
        setTimeout(checkToken, 100);
      }
    };
    checkToken();
  });
};

const getCurrencyDetails = (isoCode) => {
  return axiosInstance.get(`${API_URL}/${isoCode}`);
};

export default {
  getCurrencies,
  getCurrencyDetails,
};
