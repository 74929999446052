import { Outlet } from "react-router-dom";
import Nav from "./common/NavBar/NavBarContainer.tsx";
// import Sidebar from "../Sidebar/Sidebar";
import "./Layout.css";

const Layout = ({ currentUser, setCurrentUser, hasHRAccess }) => {
  return (
    <>
      {/* <Sidebar /> */}
      <Nav
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        hasHRAccess={hasHRAccess}
      />
      <Outlet />
    </>
  );
};

export default Layout;
