import React, { Suspense, lazy, useState, useCallback, memo } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Skeleton,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Drawer,
  Divider,
  ListItemButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { User } from "../../../types/user";
// @ts-ignore
import logoImage from "../../../pictures/logo_black.png";
import authService from "../../../services/auth.service";
import { FileSpreadsheet, Receipt, Menu, Sparkle } from "lucide-react";

// 懶加載組件
const UserMenu = lazy(() => import("./UserMenu.tsx"));

interface NavBarContainerProps {
  currentUser?: {
    user: User;
  };
  hasHRAccess?: boolean;
  setCurrentUser?: (user: { user: User } | null) => void;
}

// 子組件的 Props 類型
interface NavButtonsProps {
  navItems: NavItem[];
  location: ReturnType<typeof useLocation>;
}

interface DrawerContentProps extends NavButtonsProps {
  onClose: () => void;
}

// 簡單的佔位元件
const UserMenuPlaceholder: React.FC = () => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Skeleton variant="circular" width={32} height={32} />
    <Skeleton variant="text" width={100} />
  </Box>
);

// 導航項目定義
interface NavItem {
  text: string;
  icon: React.ReactNode;
  path: string;
}

// 抽離的導航按鈕組件
const NavButtons = memo<NavButtonsProps>(({ navItems, location }) => (
  <Box sx={{ display: "flex", flexGrow: 1, ml: 4 }}>
    {navItems.map((item) => (
      <Button
        key={item.path}
        component={Link}
        to={item.path}
        startIcon={item.icon}
        sx={{
          color: "#2c3e50",
          mx: 1,
          position: "relative",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          ...(location.pathname === item.path && {
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "2px",
              backgroundColor: "#E31837",
            },
            fontWeight: 500,
          }),
          borderRadius: 0,
        }}
      >
        {item.text}
      </Button>
    ))}
  </Box>
));

// 抽離的抽屜內容組件
const DrawerContent = memo<DrawerContentProps>(
  ({ navItems, location, onClose }) => (
    <Box sx={{ width: 250 }}>
      <Box sx={{ p: 2 }}>
        <img
          src={logoImage}
          alt="logo"
          style={{ width: 130, height: "auto" }}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              onClick={onClose}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
);

const NavBarContainer: React.FC<NavBarContainerProps> = ({
  currentUser,
  setCurrentUser,
}) => {
  const { t, i18n } = useTranslation("navigation");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  // 導航項目
  const navItems = React.useMemo(() => {
    if (!currentUser?.user) return [];

    return [
      {
        text: t("monthlyOpsReport"),
        icon: <FileSpreadsheet size={20} />,
        path: "/monthlyOpsReport",
      },
      {
        text: t("expense_claim"),
        icon: <Receipt size={20} />,
        path: "/expense_claims",
      },
      {
        text: t("newMonthlyOpsReport"),
        icon: <Sparkle size={20} />,
        path: "/newMonthlyOpsReport",
      },
    ];
  }, [currentUser, t]);

  // 移動端菜單處理
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  // 處理登出
  const handleLogout = useCallback(() => {
    authService.logout();
    if (setCurrentUser) {
      setCurrentUser(null);
    }
  }, [setCurrentUser]);

  // 處理語言切換
  const handleLanguageChange = useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
      localStorage.setItem("language", lng);
    },
    [i18n]
  );

  // 修改更新用戶資料的處理方式
  const handleUserUpdate = useCallback(
    (userData: any) => {
      if (setCurrentUser) {
        // 使用 setTimeout 來確保狀態更新不會立即觸發重渲染
        setTimeout(() => {
          setCurrentUser(userData);
        }, 100);
      }
    },
    [setCurrentUser]
  );

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#f8f9fa",
          color: "#2c3e50",
          boxShadow: "0 2px 4px rgba(0,0,0,0.08)",
        }}
      >
        <Toolbar>
          {isMobile && currentUser && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <Menu size={24} />
            </IconButton>
          )}

          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src={logoImage}
              alt="logo"
              style={{
                width: 130,
                height: "auto",
                marginRight: theme.spacing(4),
              }}
            />
          </Link>

          {!isMobile && <NavButtons navItems={navItems} location={location} />}

          <Box sx={{ ml: "auto" }}>
            {!currentUser ? (
              <Button component={Link} to="/register" color="inherit">
                {t("sign_up")}
              </Button>
            ) : (
              <Suspense fallback={<UserMenuPlaceholder />}>
                <UserMenu
                  currentUser={currentUser}
                  handleLogout={handleLogout}
                  updateCurrentUser={handleUserUpdate}
                  changeLanguage={handleLanguageChange}
                />
              </Suspense>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", lg: "none" }, // 改為 lg
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        <DrawerContent
          navItems={navItems}
          location={location}
          onClose={handleDrawerToggle}
        />
      </Drawer>
    </>
  );
};

// 添加顯示名稱
NavButtons.displayName = "NavButtons";
DrawerContent.displayName = "DrawerContent";
NavBarContainer.displayName = "NavBarContainer";

export default NavBarContainer;
