// store/locationsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// 异步获取 locations 数据
export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/locations`
    );
    return response.data.data;
  }
);

const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    locations: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.locations = action.payload;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// 选择器函数
export const selectLocations = (state) => state.locations.locations;
export const selectLocationById = (state, locationId) =>
  state.locations.locations.find((loc) => loc._id === locationId);

// 导出获取位置名称的函数
export const getLocationName = (locationId) => (state) => {
  const location = selectLocationById(state, locationId);
  return location ? location.nameLocal : "Unknown Location";
};

export default locationsSlice.reducer;
