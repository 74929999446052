import axiosInstance from "./axios.config.ts";

const API_URL = "/monitor";

interface UserData {
  token: string;
  user: {
    _id: string;
    name: string;
    email: string;
    role: string;
    isVerified: boolean;
    status: string;
    profile: {
      chineseName?: string;
      englishName?: string;
    };
    isProfileComplete: boolean;
    createdAt: string;
    updatedAt: string;
  };
}

interface DeviceInfo {
  userAgent: string;
  ip: string;
}

interface OnlineUser {
  userId: string;
  name: string;
  lastActivity: string;
  deviceInfo: DeviceInfo;
  idleTime: number;
}

interface OnlineUsersResponse {
  totalUsers: number;
  users: OnlineUser[];
}

class ActivityService {
  static async getOnlineUsers() {
    const response = await axiosInstance.get<OnlineUsersResponse>(
      `${API_URL}/online-users`
    );
    return response.data;
  }

  static async updateActivity(deviceInfo: {
    deviceId: string;
    deviceType: string;
  }) {
    const response = await axiosInstance.post(
      `${API_URL}/update-activity`,
      deviceInfo
    );
    return response.data;
  }
}

export default ActivityService;
