import { useEffect, useCallback, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import debounce from "lodash/debounce";
import ActivityService from "../services/activity.service.ts";
import AuthService from "../services/auth.service";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import { useTranslation } from "react-i18next";

const generateDeviceId = () => {
  const storedDeviceId = localStorage.getItem("deviceId");
  if (storedDeviceId) return storedDeviceId;

  const newDeviceId = crypto.randomUUID();
  localStorage.setItem("deviceId", newDeviceId);
  return newDeviceId;
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

const ACTIVITY_EVENTS = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
  "click",
] as const;

const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1小時後登出
const WARNING_TIMEOUT = 10 * 60 * 1000; // 提前10分鐘顯示警告
const DEBOUNCE_DELAY = 3000; // 3秒的防抖延遲

type ActivityEvent = (typeof ACTIVITY_EVENTS)[number];

interface ErrorResponse {
  message: string;
}

export const useActivityMonitor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inactivityTimerRef = useRef<NodeJS.Timeout>();
  const warningTimerRef = useRef<NodeJS.Timeout>();
  const isWarningShownRef = useRef<boolean>(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [countdown, setCountdown] = useState(600);

  const handleLogout = useCallback(async () => {
    try {
      await AuthService.logout();
      dispatch(logout());
      localStorage.removeItem("user");
      navigate("/");
      setShowLogoutDialog(false);
      setCountdown(600);
      enqueueSnackbar(t("common:logoutSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("common:logoutError"), { variant: "error" });
    }
  }, [navigate, location.pathname, dispatch, enqueueSnackbar, t]);

  const resetActivity = useCallback(
    debounce(async () => {
      try {
        const deviceInfo = {
          deviceId: generateDeviceId(),
          deviceType: getDeviceType(),
        };
        await ActivityService.updateActivity(deviceInfo);
      } catch (error) {
        const err = error as ErrorResponse;
        if (err.message === "Session not found") {
          handleLogout();
        } else {
          console.error("Failed to reset activity:", error);
        }
      }
    }, DEBOUNCE_DELAY),
    [handleLogout]
  );

  const handleActivity = useCallback(
    (event?: Event) => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }

      if (showLogoutDialog) {
        setShowLogoutDialog(false);
        setCountdown(600);
      }

      warningTimerRef.current = setTimeout(() => {
        setShowLogoutDialog(true);
      }, INACTIVITY_TIMEOUT - WARNING_TIMEOUT);

      inactivityTimerRef.current = setTimeout(() => {
        handleLogout();
      }, INACTIVITY_TIMEOUT);

      resetActivity();
    },
    [showLogoutDialog, handleLogout, resetActivity]
  );

  useEffect(() => {
    ACTIVITY_EVENTS.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    warningTimerRef.current = setTimeout(() => {
      setShowLogoutDialog(true);
    }, INACTIVITY_TIMEOUT - WARNING_TIMEOUT);

    inactivityTimerRef.current = setTimeout(() => {
      handleLogout();
    }, INACTIVITY_TIMEOUT);

    return () => {
      ACTIVITY_EVENTS.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });

      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }
      resetActivity.cancel();
    };
  }, [handleActivity, resetActivity, handleLogout]);

  return {
    showLogoutDialog,
    countdown,
    setCountdown,
    handleActivity,
    handleLogout,
  };
};
