import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: null,
  endDate: null,
};

const dateSlice = createSlice({
  name: "dates",
  initialState,
  reducers: {
    setDates: (state, action) => {
      state.startDate = action.payload.startDate || null;
      state.endDate = action.payload.endDate || null;
    },
  },
});

export const { setDates } = dateSlice.actions;
export default dateSlice.reducer;
