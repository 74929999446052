import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { hideModal } from "../../store/confirmationSlice";
import { useTranslation } from "react-i18next";

const ConfirmationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, message, onConfirm, actionType, onCancel } = useSelector(
    (state) => state.confirmation
  );

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(hideModal());
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    handleClose();
  };

  const getTitle = () => {
    switch (actionType) {
      case "modify":
        return t("confirmation_modal.title.modify");
      case "delete":
        return t("confirmation_modal.title.delete");
      case "unlock":
        return t("confirmation_modal.title.unlock");
      default:
        return t("confirmation_modal.title.default");
    }
  };

  const getConfirmButtonText = () => {
    switch (actionType) {
      case "modify":
        return t("confirmation_modal.button.modify");
      case "delete":
        return t("confirmation_modal.button.delete");
      case "unlock":
        return t("confirmation_modal.button.unlock");
      default:
        return t("confirmation_modal.button.default");
    }
  };

  const getConfirmButtonVariant = () => {
    switch (actionType) {
      case "modify":
        return "primary";
      case "delete":
        return "danger";
      case "unlock":
        return "danger";
      default:
        return "primary";
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          {getTitle()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("confirmation_modal.button.cancel")}
        </Button>
        <Button variant={getConfirmButtonVariant()} onClick={handleConfirm}>
          {getConfirmButtonText()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
