// deviceSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

const deviceSlice = createSlice({
  name: "device",
  initialState: {
    isMobile: isMobile,
  },
  reducers: {
    setDeviceType: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setDeviceType } = deviceSlice.actions;

export default deviceSlice.reducer;
