// src/store/currencySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import currencyService from "../services/currency.service";

// 優先貨幣
const priorityCurrencies = ["TWD", "CNY", "SGD"];

// 其他常用貨幣
const otherCommonCurrencies = [
  "USD",
  "JPY",
  "HKD",
  "EUR",
  "GBP",
  "MYR",
  "KRW",
  "PHP",
  "VND",
  "AUD",
  "IDR",
  "THB",
];

// 所有常用貨幣
const allCommonCurrencies = [...priorityCurrencies, ...otherCommonCurrencies];

// 异步获取货币数据
export const fetchCurrencies = createAsyncThunk(
  "currencies/fetchCurrencies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await currencyService.getCurrencies();
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const currencySlice = createSlice({
  name: "currencies",
  initialState: {
    currencies: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCurrencies.fulfilled, (state, action) => {
        state.status = "succeeded";

        // 提取優先貨幣，並按照指定順序排列
        const priority = priorityCurrencies
          .map((iso) => action.payload.find((currency) => currency.iso === iso))
          .filter(Boolean); // 過濾掉未找到的貨幣

        // 提取其他常用貨幣
        const otherCommon = action.payload.filter((currency) =>
          otherCommonCurrencies.includes(currency.iso)
        );

        // 提取非常用貨幣
        const others = action.payload.filter(
          (currency) => !allCommonCurrencies.includes(currency.iso)
        );

        // 按照 ISO 排序非常用貨幣
        const sortedOthers = others.sort((a, b) => a.iso.localeCompare(b.iso));

        // 合併優先貨幣、其他常用貨幣和排序後的非常用貨幣
        state.currencies = [...priority, ...otherCommon, ...sortedOthers];
      })
      .addCase(fetchCurrencies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default currencySlice.reducer;
