import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const NAMESPACES = [
  "common",
  "expense",
  "navigation",
  "monthlyOpsReport",
  "months",
  "customer",
  "ship",
  "shipTypes",
  "pic",
  "reset_password",
  "forgot_password",
  "login",
  "signup",
  "notifications",
  "profile",
  "dashboard",
  "classification",
  "newMonthlyOpsReport",
  "monthlyOpsReportEditing",
  "apiErrors",
];

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("i18nextLng") || "zhTW",
  fallbackLng: "en",
  ns: NAMESPACES,
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  resources: {}, // 先初始化空的資源
});

// 動態導入翻譯文件
const loadTranslations = async (language) => {
  const translations = {};

  try {
    await Promise.all(
      NAMESPACES.map(async (namespace) => {
        try {
          const module = await import(`./${namespace}/${language}.json`);
          translations[namespace] = module.default || module;
        } catch (error) {
          console.error(`Failed to load namespace: ${namespace}`, error);
          translations[namespace] = {};
        }
      })
    );
    return translations;
  } catch (error) {
    console.error(`Error loading translations for ${language}:`, error);
    return null;
  }
};

// 語言切換處理
export const changeLanguage = async (newLanguage) => {
  try {
    // 檢查是否已加載該語言
    if (!i18n.hasResourceBundle(newLanguage, "common")) {
      const translations = await loadTranslations(newLanguage);
      if (translations) {
        // 添加新語言包
        NAMESPACES.forEach((namespace) => {
          i18n.addResourceBundle(
            newLanguage,
            namespace,
            translations[namespace],
            true,
            true
          );
        });
      }
    }

    // 確保資源已加載後再切換語言
    await i18n.changeLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);
  } catch (error) {
    console.error(`Error changing language to ${newLanguage}:`, error);
  }
};

// 初始加載當前語言的翻譯
const initializeTranslations = async () => {
  const currentLanguage = i18n.language;
  const translations = await loadTranslations(currentLanguage);
  if (translations) {
    NAMESPACES.forEach((namespace) => {
      i18n.addResourceBundle(
        currentLanguage,
        namespace,
        translations[namespace],
        true,
        true
      );
    });
  }
};

// 執行初始化
initializeTranslations();

export default i18n;
