import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
  onConfirm: null,
  onCancel: null,
  actionType: "",
};

const confirmationSlice = createSlice({
  name: "confirmation",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.show = true;
      state.message = action.payload.message;
      state.onConfirm = action.payload.onConfirm;
      state.onCancel = action.payload.onCancel;
      state.actionType = action.payload.actionType;
    },
    hideModal: (state) => {
      state.show = false;
      state.message = "";
      state.onConfirm = null;
      state.onCancel = null;
      state.actionType = "";
    },
  },
});

export const { showModal, hideModal } = confirmationSlice.actions;

export default confirmationSlice.reducer;
