import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const LogoutCountdownDialog = ({
  open,
  countdown,
  onLogout,
  title,
  message,
  setCountdown,
}) => {
  const { t } = useTranslation("notifications", "common");

  // 倒數計時
  useEffect(() => {
    let timer;
    if (open && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            onLogout();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [countdown, open, onLogout, setCountdown]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {title || t("notifications:logout.required")}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: "center", my: 2 }}>
          <Typography variant="h6" gutterBottom>
            {message ||
              t("notifications:logout.countdown", { seconds: countdown })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
        <Button
          onClick={onLogout}
          variant="contained"
          color="primary"
          size="large"
          sx={{
            minWidth: 200,
            minHeight: 48,
            fontSize: "1.1rem",
          }}
        >
          {t("common:logout")} ({countdown})
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutCountdownDialog;
