import React, { memo } from "react";
import "./Loading.css";
import companyLogo from "../../pictures/logo_black.png";
import { usePerformanceMonitor } from "./usePerformanceMonitor";

const Loading = memo(({ size = "default" }) => {
  usePerformanceMonitor("Loading");

  const spinnerSizes = {
    small: { spinner: 100, logo: 75 },
    default: { spinner: 200, logo: 150 },
    large: { spinner: 300, logo: 225 },
  };

  const currentSize = spinnerSizes[size] || spinnerSizes.default;

  return (
    <div className="loading-overlay">
      <div
        className="loading-spinner"
        style={{
          width: currentSize.spinner,
          height: currentSize.spinner,
        }}
      />
      <img
        src={companyLogo}
        alt="Company Logo"
        className="logo-center"
        style={{
          width: currentSize.logo,
          height: "auto",
        }}
      />
    </div>
  );
});

Loading.displayName = "Loading";

export default Loading;
