import axiosInstance from "./axios.config.ts";

const API_URL = "/user";

class AuthService {
  async login(email, password) {
    const deviceInfo = {
      deviceId: generateDeviceId(),
      deviceType: getDeviceType(),
    };

    const response = await axiosInstance.post(`${API_URL}/login`, {
      email,
      password,
      deviceInfo,
    });

    if (response.data.token) {
      const userData = {
        token: response.data.token,
        user: {
          _id: response.data.user.id,
          name: response.data.user.name,
          email: response.data.user.email,
          role: response.data.user.role,
          isVerified: response.data.user.isVerified,
          status: response.data.user.status,
          profile: {
            chineseName: response.data.user.profile?.chineseName || "",
            englishName: response.data.user.profile?.englishName || "",
          },
          isProfileComplete: response.data.user.isProfileComplete,
          createdAt: response.data.user.createdAt,
          updatedAt: response.data.user.updatedAt,
        },
      };
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("deviceId", deviceInfo.deviceId);
    }
    return response;
  }

  async logout() {
    try {
      const deviceId = localStorage.getItem("deviceId");
      const user = JSON.parse(localStorage.getItem("user"));

      if (user?.token) {
        await axiosInstance.post(
          `${API_URL}/logout`,
          {
            deviceId,
          },
          {
            headers: {
              Authorization: user.token,
            },
          }
        );
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      localStorage.removeItem("user");
      localStorage.removeItem("deviceId");
    }
  }

  register(name, email, password) {
    return axiosInstance.post(`${API_URL}/register`, {
      name,
      email,
      password,
    });
  }

  resendVerificationEmail(email) {
    return axiosInstance.post(`${API_URL}/resend-verification-email`, {
      email,
    });
  }

  async checkVerificationStatus(email) {
    return axiosInstance.get(`${API_URL}/check-verification-status`, {
      params: { email },
    });
  }

  async forgotPassword(email) {
    return axiosInstance.post(`${API_URL}/forgot-password`, { email });
  }

  async resetPassword(token, password, confirmPassword) {
    return axiosInstance.post(`${API_URL}/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  getCurrentUser() {
    const storedData = JSON.parse(localStorage.getItem("user"));
    if (storedData && storedData.token && storedData.user) {
      const profileName = {
        chinese: {
          lastName: storedData.user.profile?.name?.chinese?.lastName || "",
          firstName: storedData.user.profile?.name?.chinese?.firstName || "",
        },
        english: {
          firstName: storedData.user.profile?.name?.english?.firstName || "",
          middleName: storedData.user.profile?.name?.english?.middleName || "",
          lastName: storedData.user.profile?.name?.english?.lastName || "",
        },
      };

      if (
        !storedData.user.profile?.name?.chinese?.lastName &&
        !storedData.user.profile?.name?.chinese?.firstName
      ) {
        const [lastName = "", firstName = ""] = (
          storedData.user.profile?.chineseName || ""
        ).split(" ");
        profileName.chinese = { lastName, firstName };
      }

      if (!storedData.user.profile?.name?.english?.firstName) {
        const englishNameParts = (
          storedData.user.profile?.englishName || ""
        ).split(" ");
        if (englishNameParts.length >= 2) {
          profileName.english = {
            firstName: englishNameParts[0] || "",
            middleName: englishNameParts.length > 2 ? englishNameParts[1] : "",
            lastName: englishNameParts[englishNameParts.length - 1] || "",
          };
        }
      }

      return {
        token: storedData.token,
        user: {
          _id: storedData.user._id,
          name: storedData.user.name,
          email: storedData.user.email,
          role: storedData.user.role,
          isVerified: storedData.user.isVerified,
          isProfileComplete: storedData.user.isProfileComplete,
          status: storedData.user.status,
          profile: {
            chineseName: storedData.user.profile?.chineseName || "",
            englishName: storedData.user.profile?.englishName || "",
            name: profileName,
          },
          createdAt: storedData.user.createdAt,
          updatedAt: storedData.user.updatedAt,
        },
      };
    }
    return null;
  }
}

const generateDeviceId = () => {
  const storedDeviceId = localStorage.getItem("deviceId");
  if (storedDeviceId) return storedDeviceId;

  const newDeviceId = crypto.randomUUID();
  localStorage.setItem("deviceId", newDeviceId);
  return newDeviceId;
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export default new AuthService();
